<template>
  <b-container fluid>
      <b-row class="text-dark">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row class="details">
                  <b-col lg="12" sm="12">
                    <b-row>
                      <b-col lg="12" sm="12">
                        <div>
                          <b-table striped small bordered hover :items="columns"  thead-class="hidden_header"></b-table>
                        </div>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
            <template v-if="item.attachment">
              <b-table-simple bordered>
                <b-tr>
                  <b-th>{{ $t('globalTrans.attachment') }}</b-th>
                  <b-td>
                    <a target="_blank" :href="incentiveGrantServiceBaseUrl + item.attachment" title="Attachment" class="mr-3" download=""><i class="ri-download-cloud-fill"></i></a>
                  </b-td>
                </b-tr>
              </b-table-simple>
            </template>
            <template v-if="cropShow">
              <h4 class="mb-1 text-center">{{ $t('dae_config.crop_allocation') }}</h4>
              <b-table-simple bordered>
                <b-tr>
                  <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                  <b-th>{{ $t('dae_config.crop_name') }}</b-th>
                </b-tr>
                <b-tr v-for="(crop, index) in circular.crop_details" :key="index">
                  <b-td>{{ $n(index + 1) }}</b-td>
                  <b-td>{{ ($i18n.locale=='bn') ? crop.crop_name_bn : crop.crop_name }}</b-td>
                </b-tr>
              </b-table-simple>
            </template>
            <template v-if="matShow">
              <h4 class="mb-1 text-center">{{ $t('dae_config.mat_allo') }}</h4>
              <b-table-simple bordered>
                <b-tr>
                  <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                  <b-th>{{ $t('dae_config.material_type') }}</b-th>
                  <b-th>{{ $t('dae_config.mat_name') }}</b-th>
                </b-tr>
                <b-tr v-for="(material, indx) in circular.material_details" :key="indx">
                  <b-td>{{ $n(indx + 1) }}</b-td>
                  <b-td>{{ ($i18n.locale =='bn') ? material.material_type_name_bn : material.material_type_name }}</b-td>
                  <b-td>{{ ($i18n.locale == 'bn') ? material.material_name_bn : material.material_name }}</b-td>
                </b-tr>
              </b-table-simple>
            </template>
            <template v-if="instrumentShow">
              <h4 class="mb-1" style="text-align:center">{{ $t('dae_config.instrument_info') }}</h4>
              <b-table-simple bordered>
                <b-tr>
                  <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
                  <b-th>{{ $t('farm_config.machine_type') }}</b-th>
                  <b-th>{{ $t('dae_config.instrument_name') }}</b-th>
                </b-tr>
                <b-tr v-for="(instrument, indx) in circular.instrument_details" :key="indx">
                  <b-td>{{ $n(indx + 1) }}</b-td>
                  <b-td>{{ ($i18n.locale=='bn') ? instrument.machine_name_bn : instrument.machine_name }}</b-td>
                  <b-td>{{ ($i18n.locale=='bn') ? instrument.instrument_name_bn : instrument.instrument_name }}</b-td>
                </b-tr>
              </b-table-simple>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { dateFormat } from '@/Utils/fliter'
export default {
  name: 'FormLayout',
  props: ['id', 'item'],
  created () {
    const cropData = this.getCropDetails(this.item.crop_details)
    const materialData = this.getMaterialDetails(this.item.material_details)
    const instrumentData = this.getInstrumentDetails(this.item.instrument_details)
    this.checkCircularType(this.item.circular_type)
    this.circular = Object.assign(this.item, cropData, materialData, instrumentData)
  },
  data () {
    return {
      incentiveGrantServiceBaseUrl: incentiveGrantServiceBaseUrl,
      circular: {},
      cropShow: false,
      matShow: false,
      instrumentShow: false,
      totFarmerSHow: false,
      isCashAmountShow: false,
      slOffset: 1
    }
  },
  computed: {
    columns () {
      if (this.circular.circular_type === 2) {
        if (this.$i18n.locale === 'bn') {
          return [
            { key: this.$t('globalTrans.fiscal_year'), val: this.circular.fiscal_year_bn, key1: this.$t('dae_config.season_name'), val1: this.circular.season_name_bn },
            { key: this.$t('dae_config.circular_type'), val: this.circular.circular_type_name_bn, key1: this.$t('barc_config.memo_no'), val1: this.circular.memo_no },
            { key: this.$t('barc_config.circular_name'), val: this.circular.circular_name_bn, key1: this.$t('bsri_config.project_name'), val1: this.circular.project_name_bn },
            { key: this.$t('globalTrans.amount') + ' ' + this.$t('globalTrans.tk'), val: this.$n(this.circular.amount, { useGrouping: false }), key1: this.$t('dae_subsidy.application_deadline'), val1: dateFormat(this.circular.application_deadline) },
            { key: this.$t('globalTrans.description'), val: this.circular.description }
          ]
        } else {
          return [
            { key: this.$t('globalTrans.fiscal_year'), val: this.circular.fiscal_year, key1: this.$t('dae_config.season_name'), val1: this.circular.season_name },
            { key: this.$t('dae_config.circular_type'), val: this.circular.circular_type_name, key1: this.$t('barc_config.memo_no'), val1: this.circular.memo_no },
            { key: this.$t('barc_config.circular_name'), val: this.circular.circular_name, key1: this.$t('bsri_config.project_name'), val1: this.circular.project_name },
            { key: this.$t('globalTrans.amount') + ' ' + this.$t('globalTrans.tk'), val: this.$n(this.circular.amount, { useGrouping: false }), key1: this.$t('dae_subsidy.application_deadline'), val1: dateFormat(this.circular.application_deadline) },
            { key: this.$t('globalTrans.description'), val: this.circular.description }
          ]
        }
      } else {
        if (this.$i18n.locale === 'bn') {
          return [
            { key: this.$t('globalTrans.fiscal_year'), val: this.circular.fiscal_year_bn, key1: this.$t('dae_config.season_name'), val1: this.circular.season_name_bn },
            { key: this.$t('dae_config.circular_type'), val: this.circular.circular_type_name_bn, key1: this.$t('barc_config.memo_no'), val1: this.circular.memo_no },
            { key: this.$t('barc_config.circular_name'), val: this.circular.circular_name_bn, key1: this.$t('bsri_config.project_name'), val1: this.circular.project_name_bn },
            { key: this.$t('globalTrans.description'), val: this.circular.description }
          ]
        } else {
          return [
            { key: this.$t('globalTrans.fiscal_year'), val: this.circular.fiscal_year, key1: this.$t('dae_config.season_name'), val1: this.circular.season_name },
            { key: this.$t('dae_config.circular_type'), val: this.circular.circular_type_name, key1: this.$t('barc_config.memo_no'), val1: this.circular.memo_no },
            { key: this.$t('barc_config.circular_name'), val: this.circular.circular_name, key1: this.$t('bsri_config.project_name'), val1: this.circular.project_name },
            { key: this.$t('globalTrans.description'), val: this.circular.description }
          ]
        }
      }
    }
  },
  methods: {
    getCropDetails (data) {
      return data.map(item => {
        const crop = this.$store.state.incentiveGrant.commonObj.cropList.find(crop => crop.value === item.crop_id)
        const cropObj = {
          crop_name: crop !== undefined ? crop.text_en : '',
          crop_name_bn: crop !== undefined ? crop.text_bn : ''
        }
        return Object.assign(item, cropObj)
      })
    },
    getMaterialDetails (data) {
      return data.map(item => {
        const matType = this.$store.state.incentiveGrant.commonObj.agMaterialTypeList.find(mtp => mtp.value === item.material_type_id)
        const mat = this.$store.state.incentiveGrant.commonObj.agMaterialList.find(mt => mt.value === item.material_id)
        const matTypeObj = {
          material_type_name: matType !== undefined ? matType.text_en : '',
          material_type_name_bn: matType !== undefined ? matType.text_bn : ''
        }
        const matObj = {
          material_name: mat !== undefined ? mat.text_en : '',
          material_name_bn: mat !== undefined ? mat.text_bn : ''
        }
        return Object.assign(item, matTypeObj, matObj)
      })
    },
    getInstrumentDetails (data) {
      return data.map(item => {
        const instrument = this.$store.state.incentiveGrant.commonObj.instrumentList.find(inst => inst.value === item.instrument_id)
        const instrumentObj = {
          instrument_name: instrument !== undefined ? instrument.text_en : '',
          instrument_name_bn: instrument !== undefined ? instrument.text_bn : ''
        }
        const machineType = this.$store.state.incentiveGrant.commonObj.macMachineTypeList.find(inst => inst.value === item.machine_type_id)
        const machineTypeObj = {
          machine_name: machineType !== undefined ? machineType.text_en : '',
          machine_name_bn: machineType !== undefined ? machineType.text_bn : ''
        }
        return Object.assign(item, instrumentObj, machineTypeObj)
      })
    },
    checkCircularType (circularType) {
      if (circularType === 1) {
        this.totFarmerSHow = false
        this.cropShow = false
        this.matShow = false
        this.instrumentShow = true
      }
      if (circularType === 2 || circularType === 3) {
        this.totFarmerSHow = false
        this.instrumentShow = false
        this.cropShow = true
        this.matShow = true
        this.isCashAmountShow = true
          if (this.id && this.temp_circular_type === circularType) {
            this.circular.is_cash_amount = this.temp_is_cash_amount
          } else {
            this.circular.is_cash_amount = false
          }
      } else {
        this.circular.is_cash_amount = false
        this.isCashAmountShow = false
      }
      if (circularType === 4) {
        this.instrumentShow = false
        this.cropShow = false
        this.matShow = false
        this.totFarmerSHow = true
      }
    }
  }
}
</script>
<style lang="scss">
  .details {
    h5 {
      color: green !important;
      font-size: 20px !important;
    }
    .table-bordered td:nth-child(2n+1) {
      font-weight: 700;
    }
  }
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
</style>
